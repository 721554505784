import { createStore } from "vuex";

interface Question {
  answer_options: Array<unknown>;
  id: number;
  order: number;
  quiz_id: number;
  title: string;
  points: number;
  selected_answer: number;
}

interface Quiz {
  title: string;
  color: string;
  optIn: boolean;
  skipEndScreen: boolean;
  questions: Array<Question>;
  totalQuestions: number;
}

interface Error {
  active: boolean;
  code: number;
  msg: string;
}

export default createStore({
  strict: true,
  state: {
    status: false,
    error: {} as Error,
    quizId: "",
    quiz: {} as Quiz,
    current: 0,
  },
  getters: {
    unansweredQuestions: (state, getters) => {
      return state.quiz.questions.reduce(
        (count: number, question: Question) =>
          (count += question.points === -1 ? 1 : 0),
        0
      );
    },
    totalScore: (state, getters) => {
      return state.quiz.questions.reduce(
        (count: number, question: Question) => (count += question.points),
        0
      );
    },
    quizEnd: (state, getters) => {
      return state.current === state.quiz.totalQuestions;
    },
    selectedAnswers: (state, getters) => {
      return JSON.stringify(
        state.quiz.questions.map((question) => {
          return { [question.id]: question.selected_answer };
        })
      );
    },
  },
  mutations: {
    storeQuizId(state, q) {
      state.quizId = q;
    },
    storeQuizData(state, data) {
      const quizQuestions: Array<Question> = [];

      data.questions.forEach((item: Question) => {
        item.points = -1;
        item.selected_answer = 0;
        quizQuestions.push(item);
      });

      state.quiz = {
        title: data.title,
        color: data.preferred_color,
        optIn: data.email_required,
        skipEndScreen: data.skip_end_screen,
        hide_title: data.hide_title,
        questions: quizQuestions,
        totalQuestions: data.questions.length,
      } as Quiz;
    },
    storeErrorData(state, data) {
      state.error = {
        active: true,
        code: data.code ?? 0,
        msg: data.message,
      } as Error;
    },
    setQuizStatus(state, status) {
      state.status = status;
    },
    storePoints(state, points) {
      state.quiz.questions.forEach((item: Question) => {
        if (item.id === points[0]) {
          item.points = points[1];
          item.selected_answer = points[2];
        }
      });
    },
    syncCurrent(state, next) {
      if (next) {
        state.current++;
        return;
      }

      state.current--;
      return;
    },
  },
  actions: {
    async getQuizData({ commit }) {
      await fetch(process.env.VUE_APP_API_URL + "/quiz/" + this.state.quizId, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if ("message" in data) {
              commit("storeErrorData", data);
            } else {
              commit("storeQuizData", data);
            }
          });
          commit("setQuizStatus", true);
          return;
        }
      });

      return;
    },
  },
  modules: {},
});
